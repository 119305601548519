<template>
  <base-form
    :model="settings"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    mode="edit"
  >
    <image-upload-field
      current-label="Aktuelles Logo"
      label="Logo auswählen (Möglichst Vektordatei im SVG-Format)"
      :current-image="settings.logo_url"
      @file-chosen="setLogo"
    />

    <el-divider></el-divider>

    <image-upload-field
      current-label="Aktuelles quadratisches App Icon"
      label="App Icon auswählen (quadratische PNG-Datei mit mind. 1024px Breite)"
      :current-image="settings.app_icon_url"
      allowed-file-types="image/png"
      @file-chosen="setAppIcon"
    />

    <el-divider></el-divider>

    <image-upload-field
      current-label="Aktuelles Wappen"
      label="Wappen auswählen (bitte nicht mehr als 2 Wappen/Logos in die Grafik einbinden)"
      :current-image="settings.emblem_url"
      @file-chosen="setEmblem"
    />

    <el-divider></el-divider>

    <label class="el-form-item__label">Willkommensnachricht</label>
    <text-editor
      v-model="settings.welcome_message"
      :loading="loading"
      :allow-lists="true"
      :allow-headings="true"
      :allow-images="true"
      @image-uploaded="addImage"
    ></text-editor>

    <el-divider></el-divider>

    <image-upload-field
      current-label="Aktuelles Bild Veranstaltungen Kachel"
      label="Bild Veranstaltungen Kachel auswählen"
      :current-image="settings.events_image_url"
      allowed-file-types="image/jpeg, image/png"
      @file-chosen="setEventsImage"
    />

    <el-divider></el-divider>

    <image-upload-field
      current-label="Aktuelles Bild Wissenswertes Kachel"
      label="Bild Wissenswertes Kachel auswählen"
      :current-image="settings.guide_image_url"
      allowed-file-types="image/jpeg, image/png"
      @file-chosen="setGuideImage"
    />

    <el-divider></el-divider>

    <image-upload-field
      current-label="Aktuelles Bild Videos Kachel"
      label="Bild Videos Kachel auswählen"
      :current-image="settings.videos_image_url"
      allowed-file-types="image/jpeg, image/png"
      @file-chosen="setVideosImage"
    />
  </base-form>
</template>

<script>
import ImageUploadField from "@/components/ImageUploadField"
import BaseForm from "@/components/forms/BaseForm"
import TextEditor from "@/components/TextEditor"

export default {
  components: {
    BaseForm,
    ImageUploadField,
    TextEditor
  },
  props: {
    settings: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      rules: {
        organization_signup_snippet: [
          { required: true, message: "Bitte ausfüllen", trigger: "blur" }
        ]
      }
    }
  },
  methods: {
    setLogo(file) {
      this.settings.logo = file
    },
    setAppIcon(file) {
      this.settings.app_icon = file
    },
    setEventsImage(file) {
      this.settings.events_image = file
    },
    setGuideImage(file) {
      this.settings.guide_image = file
    },
    setVideosImage(file) {
      this.settings.videos_image = file
    },
    setEmblem(file) {
      this.settings.emblem = file
    },
    addImage({ signed_id, command }) {
      this.$emit("image-uploaded", {
        settings: this.settings,
        signed_id: signed_id,
        command: command
      })
    }
  }
}
</script>
